const workExp = [
    {
        company:"Freecharge Payment Technologies:",
        Position:"Front-End Developer",
        Stack:[
            "Reactjs",
            "ES6",
            "Redux",
            "Nodejs"
        ],
        description:[
            "Currently working with freecharge as a front-end developer and partially working on node.",
            "We build web apps for Axis Bank products like Current Account, Credit Card, Saving Account and much more.",
            "Managing and conducting the development of multiple on-going projects simultaneously",
            "Following JIRA tickets from development to release.",
            "Performing bug fixes.",
            "Closely working with the Product and Team manager.",
            "Deliver engaging user experience through optimization of code, and cross-browser compatibility"
        ]

    },
    {
        company:"Bewakoof Brands Private Limited:",
        Position:"Front-End Developer & React Native",
        Stack:[
            "Angular JS",
            "React js",
            "ES6",
            "Redux",
            "React-Native",
            "TypeScript",
            "NightWatch JS"
        ],
        description:[
            "Worked with Bewakoof as Front-End and Mobile app developer, had much hands-on automation testing with the framework as NightwatchJs.",
            "Lead the mobile app development team with the size of 3 contributors for react-native ios development.",
            "Played a major role was to roll out new features to our users based on both platforms web and ios",
            "Created new feature, functionality on the eCommerce site using  reactjs and react-native development.",
            "Building stable and maintenance code using reactjs.",
            "Google Analytics: Analyze the number of users visiting the page and the most visited page at the website",
            "Working closely with stakeholders to develop technical solutions.",
            "Counting the A/B testing for better UI improvements.",
            "Collaborating with the design team to ensure we deliver an efficient UI.",
            "Good knowledge of the security on the client side.",
            "Performing bug fixes.",
            "Following JIRA tickets from development to release."

        ]
    },
    {
        company:"Better-Life Solutions Private Limited:",
        Position:"MEAN Stack Developer",
        Stack:[
            "MongoDb",
            "Express",
            "Angularjs",
            "Nodejs",
            "Ionic"
        ],
        description:[
            "Working as a MEAN stack developer, we basically build applications for the Hospitality domain, clients such as My Lab Yogi, Dr Path Lab,Thyrocare and more.",
            "API design and development for REST Services  for the product.",
            "Worked on building new Angular components for the customer facing web-app",
            "Good understanding of server side software scalability and performance.",
            "Good hand-on experience on developing web applications.",
            "Track to closed any clarification with client side.",
            "Transforming clients' needs into new product features for the high-profile external web app.",
            "Full-Stack developer experience."
        ]
    },
    {
        company:"Nine A Business Connect Private Limited:",
        Position:"ASP .Net Developer",
        Stack:[
            "C#",
            "Visual Studio",
            "SQL-Server 2008",
        ],
        description:[
            "We have developed a System for Courier Service Company.",
            "In this system we keep a track of every courier container with detailed information from boarding to departure",
            "We have built an in-house project for DHL Courier Service.",
            "For DHL Courier Service we have also created more in-house projects to extract data from various other Courier Services such as Fedex",
            "We also created a data collection website for Taj Hotel which data is being used in Business Intelligence and other Business Forecasting Strategy."
        ]
    },
    {
        company:"National Securities Depository Limited:",
        Position:"Back Office",
        Stack:[
            "Advance Excel"            
        ],
        description:[
            "Generating MIS reports, converting physical documents into excel data, had good hand over advanced excel such as VLOOKUP HLOOKUP PIVOT table and much more.",
            "Created an application for better validation and accurate data collection from the end user.",
            "Extract data from various databases such as SQL-SERVER, Microsoft Access and more.",
            "Created VBA application for data entry. Designed, recorded, and executed macros to automate data entry inputs."
        ]
    }
]


export {
    workExp
}