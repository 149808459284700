import {
  AppBar,
  Avatar,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useRef } from 'react'

const pages = [
  { tag: 'Me', scrollTo: 'me' },
  { tag: 'Skills', scrollTo: 'skills' },
  { tag: 'Work Experience', scrollTo: 'workExp' },
  { tag: 'BlockChain', scrollTo: 'blockChain' },
  { tag: 'Socials', scrollTo: 'social' },
]

const MNavBar = function () {
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleCloseNavMenu = (event, pages) => {
    setAnchorElNav(null)
    const currentElement = document.getElementById(pages.scrollTo)
    currentElement && currentElement.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              fontFamily: 'monospace',
              fontWeight: 700,
              textDecoration: 'italic',
            }}
            color="secondary"
          >
            Arbaz
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={(event) => handleCloseNavMenu(event, page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Typography
                  fontWeight={'bold'}
                  fontFamily={'monospace'}
                  textAlign="center"
                >
                  {page.tag}
                </Typography>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default MNavBar
