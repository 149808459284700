import { Box, Typography } from '@mui/material';
import react from 'react'
import AddLinkIcon from '@mui/icons-material/AddLink'


const Highlight = function ({highlighterValue}) {
  return (
    <Box p={2} bgcolor={'white'}>
      <Box display={'flex'} flexDirection="row" alignItems={'center'}>
        <AddLinkIcon fontSize="large" color="secondary" />
        <Typography
          fontWeight={'bold'}
          fontFamily="monospace"
          style={{ marginLeft: 10 }}
          variant="h4"
        >
          {highlighterValue}
        </Typography>
      </Box>
    </Box>
  )
}

export default Highlight;