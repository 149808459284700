import { Box, Chip, Grid, Paper, Skeleton, Typography } from '@mui/material'
import react from 'react'
import Highlight from '../../globals/components/Highlight'
import { blockChainConst } from './const'
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined'

const BlockChain = function () {
  return (
    <>
      <Box id="blockChain">
        <Highlight highlighterValue={'BlockChain'} />
        <Box color={'white'} p={4} bgcolor="#121723">
          <Grid container columns={{ xs: 6, sm: 6, md: 12 }}>
            {blockChainConst.map((block) => {
              return block.projectCollections.map((prj) => {
                return (
                  <Grid
                    pt={10}
                    display={'flex'}
                    justifyContent="center"
                    item
                    xs={4}
                    sm={4}
                    md={4}
                  >
                    <Box>
                      <Box textAlign={'center'}>
                        <Typography
                          color={'secondary'}
                          variant="h6"
                        >{`${prj.projectName}`}</Typography>
                        <Box p={1} pl={5} pr={5} wi>
                          <Typography variant="caption">
                            {prj.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection="row"
                        justifyContent={'space-evenly'}
                      >
                        {prj && prj.githubLink && (
                          <Box
                            onClick={() =>
                              (window.location.href = prj.githubLink)
                            }
                          >
                            <Chip
                              style={{ cursor: 'pointer' }}
                              color="info"
                              label={'GitHub'}
                              variant="outlined"
                            />
                          </Box>
                        )}
                        {prj && prj.liveDemo && (
                          <Box
                            onClick={() =>
                              (window.location.href = prj.liveDemo)
                            }
                          >
                            <Chip
                              style={{ cursor: 'pointer' }}
                              color="info"
                              label={'Demo'}
                              variant="outlined"
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                )
              })
            })}
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default BlockChain
