import { Button, Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import react, { useRef } from 'react'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import lottieJson from './tick.json'
import Lottie from 'react-lottie'
import AddLinkIcon from '@mui/icons-material/AddLink'
import Highlight from '../../globals/components/Highlight'

let skillsCollection = [
  { name: 'Javascript' },
  { name: 'React Js' },
  { name: 'Redux' },
  { name: 'React Native' },
  { name: 'Typescript' },
  { name: 'Node Js' },
  { name: 'Express' },
  { name: 'Mongo DB' },
  { name: 'Webpack' },
  { name: 'ES6' },
  { name: 'Jira' },
  { name: 'Ionic' },
  { name: 'SASS' },
  { name: 'Basic Jest' },
  { name: 'CSS' },
  { name: 'Github' },
  { name: 'Basic Docker' },
  { name: 'BitBucket' },
  { name: 'Solidity', tag: 'Blockchain' },
  { name: 'Truffle', tag: 'Blockchain' },
  { name: 'Hardhat', tag: 'Blockchain' },
  { name: 'Ether Js', tag: 'Blockchain' },
  { name: 'Smart Contracts', tag: 'Blockchain' },
  { name: 'Ethereum', tag: 'Blockchain' },
]

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: lottieJson,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Skills = function () {
  return (
    <>
      <Box id="skills">
        <Highlight highlighterValue={'Skills'} />
        <Box p={4} bgcolor="#121723">
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {skillsCollection.map((value, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Box
                  borderRadius={2}
                  bgcolor={'#1C2430'}
                  display={'flex'}
                  flexDirection="row"
                  alignItems={'Center'}
                >
                  <Lottie
                    style={{ margin: 0 }}
                    height={50}
                    width={50}
                    options={defaultOptions}
                  />
                  <Typography
                    fontFamily={'monospace'}
                    color={'white'}
                    fontWeight={'bold'}
                  >
                    {value.name}
                  </Typography>
                  {value.tag && (
                    <span
                      style={{
                        fontFamily: 'monospace',
                        color: 'orange',
                        fontSize: '8px',
                        marginTop: -10,
                        position: 'relative',
                        left: 10,
                      }}
                    >{`(${value.tag})`}</span>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default Skills
