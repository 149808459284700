const blockChainConst = [
  {
    subset: 1,
    projectCollections: [
      {
        projectName: 'Truffle Vending Machine',
        description: 'A small app related to vending machine build with truffle and smart contract.',
        githubLink: 'https://github.com/helloArbaz/truffle-vending-machine',
        liveDemo: '',
      },
      {
        projectName: 'IPFS Image Upload',
        description: 'Upload Images on IPFS server and store the data into Ethereum BlockChain ',
        githubLink: 'https://github.com/helloArbaz/ipfs-image-upload',
        liveDemo: '',
      },
      {
        projectName: 'Ether Js TODO List',
        description: 'Small TODO app Build On Solidity',
        githubLink: 'https://github.com/helloArbaz/ether-simple-todo-list',
        liveDemo: '',
      },
    ],
  },
  {
    subset: 2,
    projectCollections: [
      {
        projectName: 'Hardhat ERC20 + ERC721',
        description: 'Deployed smart contract on MATIC [Mumbai Testnet] where user can login with metamask and play memory game earn custom coin',
        githubLink: 'https://github.com/helloArbaz/hardhat-erc20-erc721',
        liveDemo: 'https://632c7945c03836456c9934e9--elegant-otter-fa0a6e.netlify.app',
      },
    ],
  },
]

export { blockChainConst }
