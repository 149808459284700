import {
  colors,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import react from 'react'
import { workExp } from './work.exp.const'
import lottieJson from './react.json'
import Lottie from 'react-lottie'
import { RampRight } from '@mui/icons-material'
import Chip from '@mui/material/Chip'
import AddLinkIcon from '@mui/icons-material/AddLink'
import Highlight from '../../globals/components/Highlight'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: lottieJson,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const StepIcon = ({ label }) => (
  <div style={{ position: 'relative' }}>
    <Lottie height={50} width={50} options={defaultOptions} />
  </div>
)

const WorkExp = function () {
  return (
    <>
      <Box id="workExp">
        <Highlight highlighterValue={'Work Experience'} />
        <Box p={4} bgcolor="#121723">
          <Stepper orientation="vertical">
            {workExp.map((step, index) => (
              <Step active={true} key={step.label}>
                <StepLabel>
                  <Typography
                    fontFamily={'monospace'}
                    fontWeight={'bold'}
                    color={'white'}
                    variant="h6"
                  >
                    {`${step.company}`}
                    <span
                      style={{
                        position: 'absolute',
                        fontSize: 15,
                        marginLeft: 10,
                        color: 'green',
                        fontWeight: 'bolder',
                        fontFamily: 'monospace',
                      }}
                    >{`[${step.Position}]`}</span>
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Box p={2}>
                    {step.Stack.map((v, i) => (
                      <Chip
                        color="info"
                        style={{ marginLeft: 10 }}
                        label={v}
                        variant="outlined"
                      />
                    ))}
                    <Box p={2}>
                      {step.description &&
                        step.description.map((v, i) => (
                          <Box pt={1}>
                            {' '}
                            <Typography
                              fontWeight={'bold'}
                              fontFamily="monospace"
                              color={'white'}
                              variant="body1"
                            >
                              {v}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    </>
  )
}

export default WorkExp
