import { Box, Button, Typography } from '@mui/material'
import react from 'react'
import Highlight from '../../globals/components/Highlight'
import { links } from './link.const'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import EmailIcon from '@mui/icons-material/Email'
import CallIcon from '@mui/icons-material/Call'

const Socials = function () {
  return (
    <>
      <Box id="social">
        <Highlight highlighterValue={'Socials'} />
        <Box color={'white'} p={4} bgcolor="#121723">
          <Box display={'flex'} flexDirection="column">
            <Box
            p={1}
              display={'flex'}
              justifyContent="center"
              border="1px solid #EFB90B"
              borderRadius={1}
            >
              <EmailIcon fontSize="large" />
              <Typography variant="h6">arbaz.hello@gmail.com</Typography>
            </Box>

            <Box mt={2} mb={2}
             p={1}
             display={'flex'}
             justifyContent="center"
             border="1px solid #EFB90B"
            >
              <CallIcon fontSize="large" />
              <Typography variant="h6">8898548222</Typography>
            </Box>
            <Button
              onClick={() => (window.location.href = links.github)}
              variant="outlined"
              color="secondary"
            >
              Github
            </Button>
            <Button
              onClick={() => (window.location.href = links.linkedin)}
              style={{ marginTop: 10 }}
              variant="outlined"
              color="secondary"
            >
              Linkedin
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Socials
