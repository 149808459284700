import { Button, Grid, Link, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Lottie from 'react-lottie'
import lottieJson from './lottie.json'
import CallIcon from '@mui/icons-material/Call'
import DownloadIcon from '@mui/icons-material/Download'
import MyPDF from '../Resume/Arbaz_Resume.pdf'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: lottieJson,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

function AboutMe() {
  return (
    <Box id="me">
      <Box bgcolor={'#121723'}>
        <Grid container columns={{ xs: 6, sm: 6, md: 12 }}>
          <Grid item xs={6} sm={6} md={6}>
            <Box
              color={'white'}
              display={'flex'}
              alignItems="center"
              justifyContent={'center'}
              flexDirection={'column'}
              p={5}
              textAlign="center"
            >
              <Typography
                fontFamily={'monospace'}
                color={'inherit'}
                fontWeight="bold"
                variant="h3"
              >
                Hi, I am Arbaz
              </Typography>
              <Typography
                fontFamily={'monospace'}
                color={'inherit'}
                fontWeight="bold"
                variant="h6"
              >
                Front End Engineer
              </Typography>
              <Typography
                fontFamily={'monospace'}
                color={'inherit'}
                fontWeight="bold"
                variant="h6"
              >
                React Native
              </Typography>
              <Typography
                fontFamily={'monospace'}
                color={'inherit'}
                fontWeight="bold"
                variant="h6"
              >
                Full Stack BlockChain Developer
              </Typography>
              <Box pt={2} fontFamily={'monospace'}>
                <Button variant="outlined" color="secondary">
                  <Box
                    display={'flex'}
                    flexDirection="row"
                    alignItems={'center'}
                    onClick={() => (window.location.href = 'tel:+918898548222')}
                  >
                    <Typography>Call </Typography>
                    <CallIcon fontSize="small" />
                  </Box>
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  variant="outlined"
                  color="secondary"
                  alignItems={'center'}
                  onClick={() => (window.location.href = MyPDF)}
                >
                  <Box display={'flex'} flexDirection="row">
                    <Typography>Resume </Typography>
                    <DownloadIcon fontSize="small" />
                  </Box>
                </Button>
              </Box>
            </Box>
            <Grid item xs={12} sm={12} md={12}>
              df
            </Grid>
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <Box p={2}>
              <Lottie height={350} options={defaultOptions} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default AboutMe
