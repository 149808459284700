import './App.css'
import MNavBar from './globals/components/MNavBar/MNavBar'
import { Box } from '@mui/system'
import AboutMe from './components/AboutMe/AboutMe'
import Skills from './components/Skills/Skills'
import WorkExp from './components/WorkExp/WorkExp'
import BlockChain from './components/Blockchain/Blockchain'
import Socials from './components/Social/Social'

function App() {
  return (
    <Box pt={5}>
      <MNavBar />
      <AboutMe />
      <Skills />
      <WorkExp />
      <BlockChain/>
      <Socials/>
    </Box>
  )
}

export default App
